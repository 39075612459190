import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import EchartsBar from "../../components/metrics/metrics-echarts/echarts-bar"
import { Loading } from "../../components/loading"

const EmailPage = () => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  const emailData = [
    { value: 68, name: "January" },
    { value: 62, name: "February" },
    { value: 60, name: "March" },
    { value: 58, name: "April" },
    { value: 56, name: "May" },
    { value: 48, name: "June" },
    { value: 46, name: "July" },
    { value: 38, name: "August" },
    { value: 36, name: "September" },
    { value: 28, name: "October" },
    { value: 28, name: "November" },
    { value: 28, name: "December" },
  ]

  return (
    <Layout pageTitle="Email Dashboard">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Email Dashboard</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <Card>
              <CardHeader title="Send Chart"></CardHeader>
              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsBar barId="send" barData={emailData}></EchartsBar>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader title="Receive Chart"></CardHeader>
              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsBar barId="receive " barData={emailData}></EchartsBar>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default EmailPage
